import authorization, { AuthPayload, AuthStatus } from "./authorization";

// add as required
type RequestMethod = "GET" | "HEAD" | "PUT" | "OPTIONS";

export const formattedResponse = (
  status: number,
  body: any = {},
  responseHeaders: Record<string, string | number> = {},
): Response => {
  const payloadBody = JSON.stringify(body);
  return new Response(payloadBody, {
    status,
    headers: {
      "Content-Type": "application/json",
      "Content-Length": payloadBody.length.toString(),
      ...responseHeaders,
    },
  });
};

export const corsFormattedResponse = (env, methods: RequestMethod[]) => {
  const corsHeaders = {
    "Access-Control-Allow-Origin": `${env.ALLOWED_ORIGINS}`,
    "Access-Control-Allow-Methods": methods.join(","),
    "Access-Control-Max-Age": "86400",
  };
  return (
    status: number,
    body: any = {},
    responseHeaders: Record<string, string | number> = {},
  ) => formattedResponse(status, body, { ...corsHeaders, ...responseHeaders });
};

export const withAuthorization = async (
  { request, env },
  callback: (payload: AuthPayload) => Promise<Response>,
) => {
  const auth: AuthStatus = await authorization({ request, env });

  if (auth.authorized === false) {
    return formattedResponse(401, { message: auth.message });
  }

  return callback(auth.payload);
};

/**
 * Convert cookie into usable object
 */
export function parseCookie(): { [key: string]: any } {
  return document.cookie.split("; ").reduce((prev, current) => {
    const [name, ...value] = current.split("=");
    Object.assign(prev, { [name]: value.join("=") });
    return prev;
  }, {});
}
