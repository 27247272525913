import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import {
  GiftCardRedemptionModal,
  GiftCardRedemptionModalProps,
} from "@bluebottlecoffee/design-system";
import { useRouter } from "next/router";
import { valueOrFirst } from "../lib/utils";
import { accountCreditsPage } from "../lib/link-builders";
import { trackEmailCaptured } from "../lib/chord/packages/web/src/services/analytics";
import { useUser } from "../lib/chord/packages/web/src/hooks/actions/use-user";
import { BaseProps } from "../lib/sanity/shared";
import { useAuth } from "../lib/chord/packages/web/src/hooks/actions/use-auth";
import { toGiftCardRedemptionProps } from "../lib/transformers/redemptionModal";

export type ConditionalEmailOptInProps = PropsWithChildren<{
  pageProps: BaseProps;
}>;

export const ConditionalGiftCardRedemptionModal = ({
  pageProps,
}: ConditionalEmailOptInProps) => {
  const { lang, region, giftCardRedemption } = pageProps;
  const { isLoggedIn, login } = useAuth();

  const [isOpen, setIsOpen] = useState(false);
  const { query } = useRouter();
  const router = useRouter();
  const { loadUser } = useUser();

  //  Combine all query string parameters (both historical, and queryString), map the values to a new array where each value is the result of the query search in the url.
  const queryStringArrayValues = useMemo(
    () => [
      valueOrFirst(query?.[giftCardRedemption?.queryString?.[lang]] ?? ""),
      ...(giftCardRedemption?.historicalQueryStringArray?.map((string) =>
        valueOrFirst(query?.[string?.[lang]] ?? ""),
      ) ?? []),
    ],
    [giftCardRedemption, query, lang],
  );

  // Find the first result where string is not empty.
  const redemptionEmail = queryStringArrayValues.find(
    (string) => string !== "",
  );

  useEffect(() => {
    // Display Modal if query param for email exists. (redemption-email)
    setIsOpen(!!redemptionEmail);
  }, [redemptionEmail]);

  if (!giftCardRedemption) return null;

  const navigateToAccountCredits = async () => {
    // Load user into state before pushing new page
    await loadUser();
    await router.push(accountCreditsPage({ lang, region }));
  };

  const handleLogin = async (email: string) => {
    if (!email) return;
    trackEmailCaptured({ email, name: null, placement: "" });

    await login({
      email,
      showUI: true,
      redirectURI: `${window.location.href}`,
    });

    await navigateToAccountCredits();
  };

  const props = toGiftCardRedemptionProps(
    giftCardRedemption,
    lang,
    redemptionEmail,
  );

  const loginGiftCardProps: GiftCardRedemptionModalProps = {
    ...props,
    handleLogin,
    navigateToAccountCredits,
    isLoggedIn,
    isOpen,
  };

  return redemptionEmail ? (
    <GiftCardRedemptionModal {...loginGiftCardProps} />
  ) : (
    <></>
  );
};
