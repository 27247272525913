/* eslint-disable default-param-last */
/* eslint-disable import/no-unresolved */
import {
  ADD_SITE_WIDE_PROMOTION,
  REMOVE_SITE_WIDE_PROMOTION,
} from "~/redux/actions/types";

import initialState from "~/redux/store/initial-state";

const siteWidePromotion = (state = initialState.siteWidePromotion, action) => {
  switch (action.type) {
    case ADD_SITE_WIDE_PROMOTION: {
      const { code, discount, includeSubscriptions } = action.payload;

      return {
        ...state,
        code,
        discount,
        includeSubscriptions,
      };
    }

    case REMOVE_SITE_WIDE_PROMOTION: {
      return {
        ...initialState.siteWidePromotion,
      };
    }

    default:
      return state;
  }
};

export default siteWidePromotion;
