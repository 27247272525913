/* eslint-disable import/no-unresolved */
import {
  SUBSCRIBE_PRODUCT_ERROR,
  SUBSCRIBE_PRODUCT_REQUEST,
  SUBSCRIBE_PRODUCT_SUCCESS,
} from "~/redux/actions/types";
import { getOrLoadCart } from "./get-or-load-cart";

export const subscribeProductRequest = (sku) => ({
    type: SUBSCRIBE_PRODUCT_REQUEST,
    sku,
  });

export const subscribeProductSuccess = (
  cart,
  variant,
  quantity,
  interval,
  endDate,
) => ({
    type: SUBSCRIBE_PRODUCT_SUCCESS,
    data: cart,
    variant,
    quantity,
    interval,
    endDate,
  });

export const subscribeProductError = (error, meta = {}) => ({
    type: SUBSCRIBE_PRODUCT_ERROR,
    error: true,
    meta,
    payload: error,
  });

export const subscribeProduct =
  ({ sku, quantity, interval, endDate = {} }) =>
  async (dispatch, getState, { api }) => {
    const {
      siteWidePromotion: { code, includeSubscriptions },
    } = getState();

    const dynamicPromotions = code && includeSubscriptions ? [{ code }] : null;

    try {
      dispatch(subscribeProductRequest(sku));

      const { data: cart } = await getOrLoadCart(dispatch, getState);

      await api.subscribeProduct(
        cart,
        { sku, quantity, interval, endDate },
        dynamicPromotions,
      );

      const updatedCart = await api.fetchCart(cart.number);

      const addLineItem = updatedCart.lineItems.find(
        (li) => li.variant.sku === sku,
      );

      const {variant} = addLineItem;

      dispatch(
        subscribeProductSuccess(
          updatedCart,
          variant,
          quantity,
          interval,
          endDate,
        ),
      );
    } catch (error) {
      dispatch(
        subscribeProductError(error, {
          sku,
          quantity,
          interval,
          endDate,
        }),
      );
      throw error;
    }
  };
