import { associateCartWithUser } from "./associate-cart-with-user";
import {
  loadCart,
  loadCartError,
  loadCartRequest,
  loadCartSuccess,
} from "./load-cart";

export const assignCurrentCart =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      const { cart: currentLocalCart } = getState();

      // if guest cart, but has more than one line item
      // we associate, and keep it as the current cart
      if (currentLocalCart.data?.lineItems?.length > 0) {
        await dispatch(associateCartWithUser());
        return;
      }

      // if guest cart but empty, we fetch distant order
      // and if that order has items, it is chosen as the current
      dispatch(loadCartRequest());

      const currentDistantCart = await api.fetchCurrentCart();

      if (currentDistantCart?.lineItems?.length > 0) {
        dispatch(loadCartSuccess(currentDistantCart));
        return;
      }

      // else, we treat the most recent of the 2 orders
      // as the current one
      if (
        new Date(currentLocalCart?.data?.createdAt) <
        new Date(currentDistantCart?.createdAt)
      ) {
        dispatch(loadCartSuccess(currentDistantCart));
        return;
      }

      await dispatch(associateCartWithUser());
      dispatch(loadCartSuccess(currentLocalCart.data));
    } catch (error) {
      // In a passwordless login flow, we are firing this Redux action in two
      // different tabs: the one requesting the login, the other being opened
      // by the link in the passwordless email. We need to keep both of these
      // tabs in sync, with regards to the cart be associated with the logged
      // in user, so fire this from both tabs and let them race. The second
      // one coming in will probably trigger a 409 as there is a lock on the
      // record. If that happens, wait 100ms and refetch the cart before
      // continuing on.
      if (error?.response?.status === 409) {
        setTimeout(() => {
          dispatch(loadCart());
        }, 100);
      } else {
        dispatch(loadCartError(error));
        throw error;
      }
    }
  };
