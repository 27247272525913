import sanityClient from "@sanity/client";

export default () =>
  sanityClient({
    projectId: process.env.SANITY_STUDIO_API_PROJECT_ID,
    dataset: process.env.NEXT_PUBLIC_SANITY_STUDIO_API_DATASET,
    token: process.env.SANITY_STUDIO_TOKEN,
    useCdn: false,
    apiVersion: "2021-08-31",
  });
