export type Dialect = {
  region: string;
  lang: string;
};

/** Used to build the returned string for the exported link builders below. */
type LocalizedURL = Dialect & {
  /**
   * The page and slug separated with a forward slash ("/").
   *
   * ### Examples
   * - "shop/all-coffee"
   * - "product/bella-donovan"
   */
  path: string;
};

/** Used to retrieve props to build the URL for each link builder below. */
type LocalizedSlug = Dialect & {
  /**
   * The last part of the Url before the query param
   *
   * ### Example
   * http://bluebottlecoffee.com/some/path/here/[slug]?some=query
   */
  slug: string;
};

/** A private function used to concatenate the {@link LocalizedURL.region}, {@link LocalizedURL.lang}, and {@link LocalizedURL.path} */
export const buildLocalizedUrl = ({
  region,
  lang,
  path,
}: LocalizedURL): string => `/${region}/${lang}/${path}`;

/**
 * Builds a localized general Page link
 *
 * /[region]/[lang]/[slug]
 */
export const generalPage = ({ slug, ...dialect }: LocalizedSlug): string =>
  buildLocalizedUrl({ ...dialect, path: `${slug}` });

/**
 * Builds a localized cart page link
 *
 * /[region]/[lang]/cart
 */
export const cartPage = (dialect: Dialect): string =>
  buildLocalizedUrl({ ...dialect, path: "cart" });

/**
 * Builds a localized privacy page link
 *
 * /[region]/[lang]/privacy
 */
export const privacyPage = (dialect: Dialect): string =>
  buildLocalizedUrl({ ...dialect, path: "privacy" });

/**
 * Builds a localized home page link
 *
 * /[region]/[lang]/
 */
export const homePage = (dialect: Dialect): string =>
  buildLocalizedUrl({ ...dialect, path: "" }).replace(/\/$/, "");

/**
 * Builds a localized ShopPage link
 *
 * /[region]/[lang]/shop/[slug]
 */
export const shopPage = ({ slug, ...dialect }: LocalizedSlug): string =>
  buildLocalizedUrl({ ...dialect, path: `shop/${slug}` });

/**
 * Builds a localized Collection link
 *
 * /[region]/[lang]/collection/[slug]
 */
export const collectionPage = ({ slug, ...dialect }: LocalizedSlug): string =>
  buildLocalizedUrl({ ...dialect, path: `collection/${slug}` });

/**
 * Builds a localized Product link
 *
 * /[region]/[lang]/product/[slug]
 */
export const productPage = ({ slug, ...dialect }: LocalizedSlug): string =>
  buildLocalizedUrl({ ...dialect, path: `product/${slug}` });

/**
 * Builds a localized Cafe link
 *
 * /[region]/[lang]/cafe/[slug]
 */
export const cafePage = ({ slug, ...dialect }: LocalizedSlug): string =>
  buildLocalizedUrl({ ...dialect, path: `cafes/${slug}` });

/**
 * Builds a localized Account link
 *
 * /[region]/[lang]/account/[slug]
 */
export const accountPage = ({ slug, ...dialect }: LocalizedSlug): string =>
  buildLocalizedUrl({ ...dialect, path: `account/${slug}` });

/**
 * Builds a localized Account Credits link
 *
 * /[region]/[lang]/account/credits
 */
export const accountCreditsPage = (dialect: Dialect): string =>
  accountPage({ ...dialect, slug: `credits` });

/**
 * Builds a localized Order Detail link
 *
 * /[region]/[lang]/account/order-history/[orderId]
 */
export const orderDetailPage = (dialect: Dialect, orderId: string): string =>
  accountPage({ ...dialect, slug: `order-history/detail?number=${orderId}` });

/**
 * Builds a localized Order History link
 *
 * /[region]/[lang]/account/order-history
 */
export const orderHistoryPage = (dialect: Dialect): string =>
  accountPage({ ...dialect, slug: `order-history` });

/**
 * Builds a localized Brew Guide link
 *
 * /[region]/[lang]/brew-guides/[slug]
 */
export const brewGuidePage = ({ slug, ...dialect }: LocalizedSlug): string =>
  buildLocalizedUrl({ ...dialect, path: `brew-guides/${slug}` });
