import IdTokenVerifier from "idtoken-verifier";

export type AuthPayload = {
  sub: string;
  email_verified: boolean;
  iss: string;
  "cognito:username": string;
  origin_jti: string;
  aud: string;
  event_id: string;
  token_use: string;
  auth_time: number;
  exp: number;
  iat: number;
  jti: string;
  email: string;
};

export type AuthStatus =
  | { authorized: true; payload: AuthPayload }
  | { authorized: false; message: string };

export async function onRequestOptionsForCors({ request, env }) {
  const corsHeaders = {
    "Access-Control-Allow-Origin": `${env.ALLOWED_ORIGINS}`,
    "Access-Control-Allow-Methods": "GET,HEAD,PUT,OPTIONS",
    "Access-Control-Max-Age": "86400",
  };

  // Make sure the necessary headers are present
  // for this to be a valid pre-flight request
  const { headers } = request;
  if (
    headers.get("Origin") !== null &&
    headers.get("Access-Control-Request-Method") !== null &&
    headers.get("Access-Control-Request-Headers") !== null
  ) {
    // Handle CORS pre-flight request.
    // If you want to check or reject the requested method + headers
    // you can do that here.
    const respHeaders = {
      ...corsHeaders,
      // Allow all future content Request headers to go back to browser
      // such as Authorization (Bearer) or X-Client-Name-Version
      "Access-Control-Allow-Headers": request.headers.get(
        "Access-Control-Request-Headers",
      ),
    };
    return new Response(null, {
      headers: respHeaders,
    });
  } else {
    // Handle standard OPTIONS request.
    // If you want to allow other HTTP Methods, you can do that here.
    return new Response(null, {
      headers: {
        Allow: "GET, HEAD, POST, OPTIONS",
      },
    });
  }
}

export default async ({ request, env }): Promise<AuthStatus> => {
  try {
    const issuer = `https://cognito-idp.${env.AWS_COGNITO_REGION}.amazonaws.com/${env.AWS_COGNITO_USER_POOL_ID}`;
    const verifier = new IdTokenVerifier({
      issuer,
      audience: env.AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
      jwksURI: `${issuer}/.well-known/jwks.json`,
    });

    if (!request.headers.has("Authorization")) {
      return {
        authorized: false,
        message: "Missing 'Authorization' header",
      };
    }

    const token = request.headers.get("Authorization");

    return new Promise((resolve) => {
      verifier.verify(token, (err, payload) => {
        if (err) {
          return resolve({
            authorized: false,
            message: `Authorization Failed: ${err.message}`,
          });
        }

        return resolve({ authorized: true, payload: payload as AuthPayload });
      });
    });
  } catch (err) {
    return {
      authorized: false,
      message: err.message,
    };
  }
};
