import { LoginFormCopy } from "@bluebottlecoffee/design-system";
import { GiftCardFormCopy } from "@bluebottlecoffee/design-system/dist/GiftCard/GiftCardForm";
import { ConsentManagerCopyProps } from "../../components/ConsentManagerWrapper";
import { filters, keyof } from "./utils";
import { SearchDialogCopy } from "../../components/SearchDialog";

import {
  AnnouncementBar,
  CafesPage,
  Cart,
  EmailOptIn,
  Footer,
  GiftCardRedemption,
  Nav,
  ProductRec,
  ShopCollection,
} from "../sanity-schema";
import {
  buildTranslationGroupQuery,
  TranslationGroupSanityType,
} from "./translation-group-queries";
import { shopCardDereferencingQuery } from "./shop-card-queries";
import { SourceProductRec } from "../transformers/product-recs";
import { SourceShopCollectionProduct } from "../algolia/types";
import {
  forSaleDereferencedVariants,
  optionTypesProjection,
  subscriptionOptionsProjection,
  subscriptionProjection,
} from "./product-queries";

export enum SharedQueryKey {
  ANNOUNCEMENT_BAR = "announcementBar",
  CAFES = "cafesPage",
  CART = "cart",
  CONSENT_MANAGER = "consentManagerCopy",
  EMAIL_OPT_IN = "emailOptIn",
  FOOTER = "footer",
  GIFT_CARD_FORM_COPY = "giftCardFormCopy",
  GIFT_CARD_REDEMPTION = "giftCardRedemption",
  LOGIN_DIALOG = "loginDialogCopy",
  NAV = "nav",
  PRODUCT_RECS = "productRecs",
  PRODUCT_SEARCH = "productSearch",
}

export interface PageLink {
  name: string;
  slug: string;
}

export interface BaseSanityQueryType {
  [SharedQueryKey.ANNOUNCEMENT_BAR]: AnnouncementBar;
  [SharedQueryKey.CONSENT_MANAGER]: TranslationGroupSanityType;
  [SharedQueryKey.EMAIL_OPT_IN]: EmailOptIn;
  [SharedQueryKey.FOOTER]: Footer;
  [SharedQueryKey.GIFT_CARD_FORM_COPY]: TranslationGroupSanityType;
  [SharedQueryKey.GIFT_CARD_REDEMPTION]: GiftCardRedemption;
  [SharedQueryKey.LOGIN_DIALOG]: TranslationGroupSanityType;
  [SharedQueryKey.NAV]: Nav;
  [SharedQueryKey.PRODUCT_RECS]: Omit<ProductRec, "products"> & {
    products: SourceProductRec[];
  };
  [SharedQueryKey.PRODUCT_SEARCH]: TranslationGroupSanityType;
}
export interface CafesPageQueryType extends BaseSanityQueryType {
  [SharedQueryKey.CAFES]: CafesPage;
}
export interface CartQueryType extends BaseSanityQueryType {
  [SharedQueryKey.CART]: Cart;
}

export interface BaseProps
  extends Omit<
    BaseSanityQueryType,
    // translation groups are transformed from their query type to data type
    | SharedQueryKey.CONSENT_MANAGER
    | SharedQueryKey.GIFT_CARD_FORM_COPY
    | SharedQueryKey.LOGIN_DIALOG
    | SharedQueryKey.PRODUCT_RECS
    | SharedQueryKey.PRODUCT_SEARCH
  > {
  region: string;
  lang: string;
  [SharedQueryKey.CONSENT_MANAGER]: ConsentManagerCopyProps;
  [SharedQueryKey.GIFT_CARD_FORM_COPY]: GiftCardFormCopy;
  [SharedQueryKey.LOGIN_DIALOG]: LoginFormCopy;
  [SharedQueryKey.PRODUCT_RECS]: SourceProductRec[];
  [SharedQueryKey.PRODUCT_SEARCH]: SearchDialogCopy;
}

export interface BasePropsWithSlug extends BaseProps {
  slug: string;
}

export interface CafesPageBaseProps extends BaseProps {
  [SharedQueryKey.CAFES]: CafesPage;
}

export interface CartBaseProps extends BaseProps {
  [SharedQueryKey.CART]: Cart;
}

export interface CartBasePropsWithSlug
  extends CartBaseProps,
    BasePropsWithSlug {}

const announcementBarQuery: string = `*[
  ${filters.whereType("announcementBar")} &&
  ${filters.excludeDrafts}
][0]`;

const cafesPageQuery: string = `*[
  ${filters.whereType("cafesPage")} &&
  ${filters.excludeDrafts}
][0]{
  ...,
  modules[]{
    ...,
    select(_type == "cafeLocator") => {
      ...,
      previewCafes[]->
    }
  }
}`;

const cartQuery: string = `*[
  ${filters.whereType("cart")} &&
  ${filters.excludeDrafts}
][0]`;

const footerQuery: string = `*[
  ${filters.whereType("footer")} &&
  ${filters.excludeDrafts}
][0]`;

const navQuery: string = `*[
  ${filters.whereType("nav")} &&
  ${filters.excludeDrafts}
][0]`;

const emailOptInQuery: string = `*[
  ${filters.whereType("emailOptIn")} &&
  ${filters.excludeDrafts}
][0]`;

const giftCardRedemptionQuery: string = `*[
  ${filters.whereType("giftCardRedemption")} &&
  ${filters.excludeDrafts}
][0]`;

export const productRecsQuery = (lang: string) => `*[
  ${filters.whereType("productRec")}
  && ${filters.excludeDrafts}
]{
  products[]->{
    ...
    , ${keyof<SourceProductRec>("optionTypes")}[]->${optionTypesProjection}
    , ${forSaleDereferencedVariants<SourceProductRec>("variants")}
    , ${keyof<SourceProductRec>("shopCollections")}[]->
    , ${keyof<SourceProductRec>("subscription")}->${subscriptionProjection}
    , ${keyof<SourceProductRec>(
      "subscriptionOptions",
    )}[]${subscriptionOptionsProjection(lang)}
    , "${keyof<SourceShopCollectionProduct>(
      "primaryShopCollectionName",
    )}": ${keyof<SourceProductRec>(
  "shopCollections",
)}[][0]->${keyof<ShopCollection>("name")}
    , ${shopCardDereferencingQuery}
  }
}[0]`;

const configQuery: string = `*[
  ${filters.whereType("config")} &&
  ${filters.excludeDrafts}
][0]`;

/** All the shared Sanity queries */
export const queries = {
  announcementBarQuery,
  configQuery,
  emailOptInQuery,
  footerQuery,
  giftCardRedemptionQuery,
  navQuery,
  productRecsQuery,
};

export const cafesPageQueries = {
  ...queries,
  cafesPageQuery,
};

export const cartQueries = {
  ...queries,
  cartQuery,
};

const sharedQueries = (lang: string): string => `
  "${SharedQueryKey.ANNOUNCEMENT_BAR}": ${queries.announcementBarQuery},
  "${SharedQueryKey.CONSENT_MANAGER}": ${buildTranslationGroupQuery(
  lang,
  "SegmentConsentManager",
)},
  "${SharedQueryKey.EMAIL_OPT_IN}": ${queries.emailOptInQuery},
  "${SharedQueryKey.FOOTER}": ${queries.footerQuery},
  "${SharedQueryKey.GIFT_CARD_REDEMPTION}": ${queries.giftCardRedemptionQuery},
  "${SharedQueryKey.LOGIN_DIALOG}": ${buildTranslationGroupQuery(
  lang,
  "LoginDialog",
)},
  "${SharedQueryKey.NAV}": ${queries.navQuery},
  "${SharedQueryKey.PRODUCT_RECS}": ${queries.productRecsQuery(lang)},
  "${SharedQueryKey.PRODUCT_SEARCH}": ${buildTranslationGroupQuery(
  lang,
  "ProductSearch",
)}
`;
// NOTE: The query above MUST NOT END WITH A TRAILING COMMA!

export const sharedSanityQuery = (lang: string): string => `{
  ${sharedQueries(lang)}
}`;

export const sharedSanityCafesPageQuery = (lang: string): string => `{
  ${sharedQueries(lang)},
  "${SharedQueryKey.CAFES}": ${cafesPageQueries.cafesPageQuery}
}`;

export const sharedSanityCartQuery = (lang: string): string => `{
  ${sharedQueries(lang)},
  "${SharedQueryKey.CART}": ${cartQueries.cartQuery},
  "${SharedQueryKey.GIFT_CARD_FORM_COPY}": ${buildTranslationGroupQuery(
  lang,
  "GiftCardFormCopy",
)}
}`;
