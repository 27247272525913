import {
  QuantityLabel,
  Variant,
  SubscriptionInterval,
  Subscription,
  ProductSubscriptionOption,
  OptionValue,
  SanityKeyed,
  Product,
  OptionType,
} from "../sanity-schema";
import {
  DereferencedVariant,
  forSaleVariantFilter,
  variantsProjection,
} from "./variant-queries";
import { filters, getLocalizedString, keyof } from "./utils";
import {
  DereferencedShopCard,
  shopCardDereferencingQuery,
} from "./shop-card-queries";

export type DereferencedOptionType = Omit<OptionType, "optionValues"> & {
  optionValues: OptionValue[];
};

export type DereferencedSubscription = {
  intervals: Pick<
    SubscriptionInterval,
    "_id" | "length" | "presentation" | "unit"
  >[];
};

export type DereferencedSubscriptionOption = {
  _key: string;
  item: Pick<Variant, "sku">;
  quantityLabel: Pick<QuantityLabel, "label" | "quantity">;
  showSubcriptionPriceDisclaimer: boolean;
};

export type SanityQueryType = {
  name: Product["name"];
  slug: Product["slug"];
  description: Product["description"];
  images: Product["images"];
  metaTags: Product["metaTags"];
  modules: Product["modules"];
  variants: DereferencedVariant[];
  optionTypes: DereferencedOptionType[];
  subscription: DereferencedSubscription[];
  subscriptionOptions: DereferencedSubscriptionOption[];
  subscriptionType: Product["subscriptionType"];
};

export const optionTypesProjection = `{
  ...,
  ${keyof<Variant>("optionValues")}[]->{
    ${keyof<OptionValue>("presentation")}
  }
}`;

export const subscriptionProjection = `{
  ${keyof<Subscription>("intervals")}[]->{
    ${keyof<SubscriptionInterval>("_id")},
    ${keyof<SubscriptionInterval>("length")},
    ${keyof<SubscriptionInterval>("presentation")},
    ${keyof<SubscriptionInterval>("unit")},
  }
}`;

export const subscriptionOptionsProjection = (lang: string) => `{
  ${keyof<SanityKeyed<ProductSubscriptionOption>>("_key")},
  ${keyof<ProductSubscriptionOption>("showSubcriptionPriceDisclaimer")},
  ${keyof<ProductSubscriptionOption>("item")}->{
    ${keyof<Variant>("sku")}},
  ${keyof<ProductSubscriptionOption>("quantityLabel")}->{
    "label": ${getLocalizedString<QuantityLabel>("label", lang)},
    ${keyof<QuantityLabel>("quantity")}
  }
}`;
// prettier-ignore
const dereferencedVariants = <T>(variantsField: keyof T) => `${String(variantsField)}[]->${variantsProjection}`;

// prettier-ignore
export const forSaleDereferencedVariants = <T>(variantsField: keyof T) => `"${String(variantsField)}" : (${dereferencedVariants<T>(variantsField)})${forSaleVariantFilter}`

const productForSaleFilter = `${keyof<Product>("forSale")} == true`;

const isIndexable = (indexable: boolean = true) => {
  const logicalOperator = indexable ? "!=" : "==";

  // because not all products will have this value set, we only want to
  // exclude products that have `excludeFromIndex` explicitly set to `true`
  return `${keyof<Product>("excludeFromIndex")}${logicalOperator}true`;
};

// prettier-ignore
const dereferencedVariantsWithForSaleProjection = `${keyof<Product>("variants")}[]->{${keyof<Variant>("forSale")}}`;

// prettier-ignore
const productVariantForSaleFilter = `count((${dereferencedVariantsWithForSaleProjection})${forSaleVariantFilter}) > 0`;

export const productFilters = `
  ${productForSaleFilter}
  && ${productVariantForSaleFilter}
  && ${filters.excludeDrafts}
`;

export const indexableProductFilters = `
  ${productFilters}
  && ${isIndexable(true)}
`;

const productFilter = (slug: string) => `*[
  ${filters.whereType("product")}
  && ${filters.matchSlug<Product>("slug", slug)}
  && ${productFilters}
][0]`;

export const buildSanityQueryVariantsOnly = (slug: string) => `
${productFilter(slug)}{
  ${forSaleDereferencedVariants<Product>("variants")}
}`;

// prettier-ignore
export const productProjection = (lang: string) => `{
  ${keyof<Product>("name")},
  ${keyof<Product>("slug")},
  ${keyof<Product>("description")},
  ${keyof<Product>("images")},
  ${keyof<Product>("metaTags")},
  ${keyof<Product>("modules")},
  ${forSaleDereferencedVariants<Product>("variants")},
  ${keyof<Product>("optionTypes")}[]->${optionTypesProjection},
  ${keyof<Product>("quickSubscribeCard")},
  ${keyof<Product>("subscription")}->${subscriptionProjection},
  ${keyof<Product>("subscriptionOptions")}[]${subscriptionOptionsProjection(
  lang,
)},
  ${keyof<Product>("subscriptionType")},
  ${keyof<Product>("isGiftCard")},
  ${keyof<Product>("excludeFromIndex")}
}`;

// prettier-ignore
export const buildSanityQuery = (lang: string, slug: string) => `
  ${productFilter(slug)}${productProjection(lang)}
`;

export const shippingCopyQuery: string = `*[
  ${filters.whereType("shippingCopy")} &&
  ${filters.excludeDrafts}
][0]`;

/**
 * SourceProduct type
 *
 * Representative of a Sanity `Product` as returned by the productsQuery
 */
export type SourceProduct = Omit<
  Product,
  "variants" | "shopCollections" | "shopCard" | "optionTypes"
> & {
  optionTypes: DereferencedOptionType[];
  shopCard?: DereferencedShopCard;
  variants: DereferencedVariant[];
};

export const indexableProductsQuery = `
  *[
      ${filters.whereType("product")}
      && ${indexableProductFilters}
    ] {
        ...
        , ${keyof<Product>("optionTypes")}[]->${optionTypesProjection}
        , ${shopCardDereferencingQuery}
        , ${forSaleDereferencedVariants<SourceProduct>("variants")}
    }
  `;
