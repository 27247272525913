/* eslint-disable no-use-before-define */
import { parseCookie } from "../../functions/lib/common";

/**
 * Segment middleware function for decorating events fired from the Chord SDK
 */
export const assignEventProps = ({ payload, next }) => {
  const { event, properties: eventProps } = payload.obj;

  if (event === Event.SubscriptionCancelled) {
    addCancellationReasons(eventProps);
  }

  next(payload);
};

/**
 * Events
 */
enum Event {
  SubscriptionCancelled = "Subscription Cancelled",
}

/**
 * Add cancellationReasons to event properties
 *
 * Parses cookie for subscriptionCancellationReasons, splits the string,
 * and then assigns the resulting array to the original event properties object.
 *
 * @param { any } eventProps Segment event props
 *
 * @returns { any } Segment event props with cancellationReasons attribute
 */
function addCancellationReasons(eventProps) {
  const { subscriptionCancellationReason: reason } = parseCookie();
  if (reason) {
    Object.assign(eventProps, {
      cancellationReason: decodeURIComponent(reason),
    });
  }
}
