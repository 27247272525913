/* eslint-disable no-use-before-define */
import {
  CartLineItem,
  Image,
  Manifest,
  Money,
  OptionValue,
  Order,
  OrderAddress,
  OrderLineItem,
  Payment,
  PaymentSource,
  Shipment,
} from "@bluebottlecoffee/design-system/dist/lib/types";
import { QueryParam } from "@bluebottlecoffee/design-system/dist/lib/types/router";
import { ParsedUrlQuery } from "querystring";
import {
  LoginFormCopy,
  SubscriptionPreviewType,
} from "@bluebottlecoffee/design-system";
import { addDays, addMonths } from "date-fns";
import Dinero from "dinero.js";
import cookie from "js-cookie";
import { GiftCardFormCopy } from "@bluebottlecoffee/design-system/dist/GiftCard/GiftCardForm";
import sanityClient from "./sanity-client";
import { Config } from "./sanity-schema";
import {
  BaseSanityQueryType,
  queries,
  sharedSanityQuery,
  sharedSanityCartQuery,
  CartQueryType,
  cartQueries,
  CafesPageQueryType,
  sharedSanityCafesPageQuery,
} from "./sanity/shared";
import { toCopyObject } from "./sanity/translation-group-queries";
import { Dialect, productPage } from "./link-builders";
import { ConsentManagerCopyProps } from "../components/ConsentManagerWrapper";
import {
  ChordAdjustment,
  ChordData,
  ChordDataLineItem,
  ChordImage,
  ChordResponsiveImage,
} from "./chord/types";
import { ChordCart } from "./chord/types/cart";
import { AlgoliaCollectionPreview } from "./algolia/collection-preview";
import { ConversionSchema } from "./transformers/conversion";
import { ShopCardPropsWithSlug } from "./transformers/shop-card";
import { SearchDialogCopy } from "../components/SearchDialog";
import { AlgoliaRecommendProduct } from "./algolia/types";

/**
 * Checks that the @param argument is not `undefined`
 *
 * @param {(T | undefined)} argument The value to check against
 * @return {bool} Whether the argument is not equal to `undefined`
 * */
export function isDefined<T>(argument: T | undefined): argument is T {
  return argument !== undefined && argument != null;
}

/**
 * Returns the type provided to an array type
 *
 * ### Example
 * ```ts
 * ElementOf<any[]>; // any
 * ElementOf<number[]>; // number
 * ElementOf<(string | MyClass)[]>; // string | MyClass
 * ```
 */
export type ElementOf<ArrayType extends Array<any>> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

type DebugPrintCallback = (message: any) => void;

/**
 * Deduplicates an array
 *
 * Used as a callback to `filter`.
 *
 * ## Example
 * ```ts
 * [1,2,2,3].filter(onlyUnique); // [1,2,3]
 * ```
 */
export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

/**
 * Generates a random key.
 * Useful to render React components properly
 *
 * @returns string
 */

export function generateRandomKey() {
  return Math.random().toString(36).substring(2);
}

/**
 * Casts the element type to another type
 *
 * Used to cast generic type to a specific type
 * @param element
 */

export function cast<R, T>(element: T): R {
  return element as unknown as R;
}

/**
 * Returns true if the element is of type AlgoliaCollectionPreview[]
 * @param array
 * @returns boolean
 */

export function elementTypeisCollection(
  array: any,
): array is AlgoliaCollectionPreview[] {
  return array.length > 0 ? "shopCards" in array[0] : false;
}

/**
 * Returns true if the element is of type ShopCardPropsWithSlug[]
 * @param array
 * @returns boolean
 */

export function elementTypeisShopCards(
  array: any,
): array is ShopCardPropsWithSlug[] {
  return array.length > 0 ? "bestValueVariant" in array[0] : false;
}

/**
 * Returns true if the element is of type SubscriptionPreviewType[]
 * @param array
 * @returns boolean
 */

export function elementTypeisSubscriptions(
  array: any,
): array is SubscriptionPreviewType[] {
  return array.length > 0 ? "cards" in array[0] : false;
}

/**
 * Returns true if the element is of type ConversionSchema
 * @param object
 * @returns boolean
 */

export function elementTypeisConversion(
  object: any,
): object is ConversionSchema {
  return "variants" in object;
}

/**
 * Used with a value that can be either a value or an array.
 *
 * Commonly used with query params.
 *
 * ## Example
 * ```ts
 * const myFilterArray = ["Washed", "Floral/Fruity"];
 * const myFilterString = "Light";
 *
 * return valueOrFirst(myFilterArray); // "Washed"
 * return valueOrFirst(myFilterString); // "Light"
 * ```
 */
export function valueOrFirst<T>(value: T | T[]): T {
  if (Array.isArray(value) && value.length > 0) return value[0];

  return value as T;
}

/** Verifies the node environment is set to "development" */
export const getIsDebug = () => process.env.NODE_ENV === "development";

/**
 * Verifies the global `window` object is available, signifying we're running
 * in a browser and not inside of a nodejs environment.
 */
export const getIsClientSide = () => typeof window !== "undefined";

/**
 * Used to build a `debugPrint` function for a class.
 *
 * The returned `debugPrint` function will only log messages to the console when
 * the 'development' node environment.
 *
 * ##Usage
 * ```ts
 * const debugPrint = debugPrintBuilder("myClassName");
 * ...
 * debugPrint("in someFunction"); // myClassName: in someFunction
 * ```
 * ## Output Format
 * Message format when generating the static site @classPrefix|SSG: @message
 * Message format when rendering client side @classPrefix: @message
 * @param classPrefix the class you're executing your debug statements in
 * @param message the message or object you want to log. Objects should be sent
 * on their own and not interpolated inside a string or the object's toString
 * method will be used to serialize the object as a string and all you'll see
 * is `[object: Object]` inside of the log message.
 */
export const debugPrintBuilder =
  (classPrefix: string): DebugPrintCallback =>
  (message: any): void => {
    const ssgMarker = getIsClientSide() ? "" : "|SSG";

    if (getIsDebug()) {
      if (typeof message === "object") {
        // eslint-disable-next-line no-console
        console.log(`${classPrefix}${ssgMarker}:`);

        // eslint-disable-next-line no-console
        console.log(message);
      } else {
        // eslint-disable-next-line no-console
        console.log(`${classPrefix}${ssgMarker}: ${message}`);
      }
    }
  };

type GeneratedStaticProps = {
  region: string;
  lang: string;
  slug: string;
};

/**
 * Gets the generated query params from static props or the router's query.
 *
 * These arguments are available at all times including during static site
 * rendering (SSG - aka Static Site Generation) so you can use these at any time.
 *
 * ## Where do they come from?
 * The shape of our url is as follows:
 * http://domain.com/[region]/[lang]/page/[slug]?other=query&params=here.
 *
 * ## Usage
 *
 * ### GetStaticProps
 * ```ts
 export const getStaticProps: GetStaticProps<PageData> = async (context) => {
  const { region, lang, slug } = getPageArgs(context.params);
  ...
 }
 * ```
 *
 * ### NextRouter
 * ```ts
 * const router = useRouter();
 *
  * const { region, lang, slug } = getPageArgs(router.query);
  *
  * // Note, for any other query related arguments you expect to be changed on
  * // the client or that you expect to be dynamic (anything not available
  * // during build time via some kind of query [Sanity, Algolia, etc.]) you'll
  * // want to wait until the router is ready. You'll also want to listen for
  * // changes to the router's path in case a user changes the query while on
  * // the page.
  *
  * useEffect(() => {
  * if(router.isReady) {
  *   // Do something with clientSide query params here.
  *   // If you only expect one param by the key you're looking for then you can
  *   // use the other util `valueOrFirst` to ensure you're only getting one
  *   // back.
  *   // const navQuery = valueOrFirst(router.query.nav);
  * }
  * }, [router.isReady, router.asPath])
 * ```
 */
export const getPageArgs = (query: ParsedUrlQuery): GeneratedStaticProps => {
  const { region: regionParam, lang: langParam, slug: slugParam } = query;
  return {
    region: valueOrFirst(regionParam!),
    lang: valueOrFirst(langParam!),
    slug: valueOrFirst(slugParam!),
  };
};

/**
 * Stringifies comma separates array types and simply returns strings
 */
export const getPrintableQueryParam = (param: QueryParam) =>
  Array.isArray(param) ? param.join(",") : param;

/** use these for pages that do not have the MiniCart Slideover Dialog trigger */
export const getDefaultStaticProps = async (context) => {
  const { region, lang } = getPageArgs(context.params);

  const {
    announcementBar,
    consentManagerCopy,
    emailOptIn,
    footer,
    giftCardFormCopy,
    giftCardRedemption,
    loginDialogCopy,
    nav,
    productRecs,
    productSearch,
  } = await sanityClient().fetch<BaseSanityQueryType>(sharedSanityQuery(lang));

  return {
    props: {
      announcementBar,
      emailOptIn,
      footer,
      giftCardFormCopy: toCopyObject<GiftCardFormCopy>(lang, giftCardFormCopy),
      giftCardRedemption,
      lang,
      nav,
      region,
      loginDialogCopy: toCopyObject<LoginFormCopy>(lang, loginDialogCopy),
      consentManagerCopy: toCopyObject<ConsentManagerCopyProps>(
        lang,
        consentManagerCopy,
      ),
      productRecs: productRecs.products,
      productSearch: toCopyObject<SearchDialogCopy>(lang, productSearch),
    },
  };
};

export const getDefaultStaticPropsWithSlug = async (context) => {
  const { region, lang, slug } = getPageArgs(context.params);

  const {
    announcementBar,
    consentManagerCopy,
    emailOptIn,
    footer,
    giftCardFormCopy,
    giftCardRedemption,
    loginDialogCopy,
    nav,
    productRecs,
    productSearch,
  } = await sanityClient().fetch<BaseSanityQueryType>(sharedSanityQuery(lang));

  return {
    props: {
      announcementBar,
      emailOptIn,
      footer,
      giftCardFormCopy: toCopyObject<GiftCardFormCopy>(lang, giftCardFormCopy),
      giftCardRedemption,
      lang,
      nav,
      region,
      slug,
      loginDialogCopy: toCopyObject<LoginFormCopy>(lang, loginDialogCopy),
      consentManagerCopy: toCopyObject<ConsentManagerCopyProps>(
        lang,
        consentManagerCopy,
      ),
      productRecs: productRecs.products,
      productSearch: toCopyObject<SearchDialogCopy>(lang, productSearch),
    },
  };
};

export const getDefaultStaticPropsCafesPage = async (context) => {
  const { region, lang } = getPageArgs(context.params);

  const {
    announcementBar,
    cafesPage,
    consentManagerCopy,
    emailOptIn,
    footer,
    giftCardFormCopy,
    giftCardRedemption,
    loginDialogCopy,
    nav,
    productRecs,
    productSearch,
  } = await sanityClient().fetch<CafesPageQueryType>(
    sharedSanityCafesPageQuery(lang),
  );

  return {
    props: {
      announcementBar,
      cafesPage,
      emailOptIn,
      footer,
      giftCardFormCopy: toCopyObject<GiftCardFormCopy>(lang, giftCardFormCopy),
      giftCardRedemption,
      lang,
      nav,
      region,
      loginDialogCopy: toCopyObject<LoginFormCopy>(lang, loginDialogCopy),
      consentManagerCopy: toCopyObject<ConsentManagerCopyProps>(
        lang,
        consentManagerCopy,
      ),
      productRecs: productRecs.products,
      productSearch: toCopyObject<SearchDialogCopy>(lang, productSearch),
    },
  };
};

/** use this for full cart page */
export const getDefaultStaticPropsMiniCart = async (context) => {
  const { region, lang } = getPageArgs(context.params);

  const {
    announcementBar,
    cart,
    consentManagerCopy,
    emailOptIn,
    footer,
    giftCardFormCopy,
    giftCardRedemption,
    loginDialogCopy,
    nav,
    productRecs,
    productSearch,
  } = await sanityClient().fetch<CartQueryType>(sharedSanityCartQuery(lang));

  return {
    props: {
      announcementBar,
      cart,
      emailOptIn,
      footer,
      giftCardRedemption,
      lang,
      nav,
      region,
      loginDialogCopy: toCopyObject<LoginFormCopy>(lang, loginDialogCopy),
      consentManagerCopy: toCopyObject<ConsentManagerCopyProps>(
        lang,
        consentManagerCopy,
      ),
      productRecs: productRecs.products,
      productSearch: toCopyObject<SearchDialogCopy>(lang, productSearch),
      giftCardFormCopy: toCopyObject<GiftCardFormCopy>(lang, giftCardFormCopy),
    },
  };
};

/** use this for pages that have the MiniCart Slideover Dialog trigger (ie: Product pages) */
export const getDefaultStaticPropsMiniCartWithSlug = async (context) => {
  const { region, lang, slug } = getPageArgs(context.params);

  const {
    announcementBar,
    cart,
    consentManagerCopy,
    emailOptIn,
    footer,
    giftCardFormCopy,
    giftCardRedemption,
    loginDialogCopy,
    nav,
    productRecs,
    productSearch,
  } = await sanityClient().fetch<CartQueryType>(sharedSanityCartQuery(lang));

  return {
    props: {
      announcementBar,
      cart,
      emailOptIn,
      footer,
      giftCardRedemption,
      lang,
      nav,
      region,
      slug,
      loginDialogCopy: toCopyObject<LoginFormCopy>(lang, loginDialogCopy),
      consentManagerCopy: toCopyObject<ConsentManagerCopyProps>(
        lang,
        consentManagerCopy,
      ),
      productRecs: productRecs.products,
      productSearch: toCopyObject<SearchDialogCopy>(lang, productSearch),
      giftCardFormCopy: toCopyObject<GiftCardFormCopy>(lang, giftCardFormCopy),
    },
  };
};

export const getDefaultStaticPaths = async () => {
  const config = await sanityClient().fetch<Config>(queries.configQuery);

  const params = config.languages!.map((lang) => ({
    params: {
      region: config.region,
      lang,
    },
  }));

  return {
    paths: params,
    fallback: false,
  };
};

export const getDefaultStaticPathsMiniCart = async () => {
  const config = await sanityClient().fetch<Config>(cartQueries.configQuery);

  const params = config.languages!.map((lang) => ({
    params: {
      region: config.region,
      lang,
    },
  }));

  return {
    paths: params,
    fallback: false,
  };
};

export const bbcLogoFallBackImgSrc: URL["pathname"] =
  "v1612883439/blue_bottle_brand_assets/Bottle_Logo-Small.001_031820.png";

/* <Image /> is set to unoptimized in the design system so full cloudinary URL is required
  for fallback image */
export const variantFallbackImageSmall: string =
  "https://res.cloudinary.com/hbhhv9rz9/image/upload/blue_bottle_brand_assets/Bottle_Logo-Small.001_031820.png";

/** Cart and MiniCart shared */
type CartVariantProps = {
  data: ChordCart["data"];
  dialect: Dialect;
};

export function cartVariants({
  data,
  dialect,
}: CartVariantProps): CartLineItem[] {
  const { lineItems } = data;
  if (lineItems) {
    return lineItems.map((lineItem) => {
      const {
        id,
        quantity,
        giftCards,
        // subscriptionLineItems
        variant: {
          cartLimit,
          displayPrice,
          images,
          name,
          optionsText,
          optionValues,
          price,
          regularPrice,
          sku,
          slug,
        },
      } = lineItem;
      const image = images?.[0];
      const imageAlt = image?.alt;
      const imageSrc =
        image?.smallUrl || image?.miniUrl || variantFallbackImageSmall;

      return {
        currency: data.currency,
        id,
        quantity,
        giftCards,
        variant: {
          cartLimit,
          displayPrice,
          images: [
            {
              desktop: {
                altText: imageAlt || "",
                src: imageSrc,
              },
              mobile: {
                altText: imageAlt || "",
                src: imageSrc,
              },
            },
          ],
          name,
          optionsText,
          optionValues: optionValues.map(
            ({ optionTypePresentation, presentation }) => ({
              optionTypePresentation,
              presentation,
            }),
          ),
          price: Number(price),
          regularPrice: regularPrice ? Number(regularPrice) : null,
          sku,
          slug: productPage({ slug, ...dialect }),
          subscriptions: lineItem.subscriptionLineItems.length
            ? lineItem.subscriptionLineItems
            : null,
        },
      };
    });
  }
  return [];
}

/** Originates from the Order History, History Detail, and Order Confirmation types */
type ChordOrderType = ChordData<ChordImage | ChordResponsiveImage>;

/** Originates from the Order History, History Detail, and Order Confirmation types */
type ChordOrderLineItemShared = ChordDataLineItem<
  ChordImage | ChordResponsiveImage
>;

type PromoEligible = {
  adjustments: ChordAdjustment[];
  lineItems: { adjustments: ChordAdjustment[] }[];
};

const allEligiblePromos = (data: PromoEligible): ChordAdjustment[] | null => {
  const cartAdjustments: ChordAdjustment[] = data?.adjustments;
  const lineItemsWithAdjustments: PromoEligible["lineItems"] =
    data?.lineItems?.filter((lineItem) => lineItem.adjustments?.length);
  const lineItemAdjustments: ChordAdjustment[] =
    lineItemsWithAdjustments?.flatMap((lineItem) => lineItem.adjustments);
  const eligibleAdjustments: ChordAdjustment[] = cartAdjustments
    ?.concat(lineItemAdjustments)
    ?.filter((adjustment) => adjustment.eligible);
  const eligiblePromos = eligibleAdjustments?.filter(
    (adj) => adj.sourceType === "Spree::PromotionAction",
  );
  return eligiblePromos ?? null;
};

export function discountTotal(data: PromoEligible): number {
  const promoAmounts: number[] =
    allEligiblePromos(data)?.map((promo) =>
      Math.round(parseFloat(promo.amount) * 100),
    ) ?? [];
  const discTotal = promoAmounts?.reduce((partialSum, a) => partialSum + a, 0);
  return discTotal;
}

export function getPromoLabels(data: PromoEligible): string[] {
  const allPromosWithAmt: ChordAdjustment[] =
    allEligiblePromos(data)?.filter((promo) => promo.amount !== "0.0") ?? [];
  const promosWithAmtLabels: string[] = allPromosWithAmt?.map(
    (promo) => promo.label,
  );
  const formattedLabels: string[] = promosWithAmtLabels?.map((label) =>
    label.replace(/promotion /gi, "").replace(/ \(Domain\)/gi, ""),
  );
  const uniqueFormattedLabels: string[] = formattedLabels?.filter(
    (adjustment, index) => formattedLabels?.indexOf(adjustment) === index,
  );
  return uniqueFormattedLabels;
}

export function getPromoWithCode(data: PromoEligible): string | undefined {
  const allPromosWithCodes: ChordAdjustment[] =
    allEligiblePromos(data)?.filter((promo) => promo.promotionCodeId) ?? [];
  const promoCodeValues: string[] = allPromosWithCodes?.map(
    (promo) => promo.promotionCode.value,
  );
  return promoCodeValues[0];
}

type IsFreeShippingDataType = PromoEligible & {
  shipTotal: string;
  subscriptionInCart: boolean;
};

export function isFreeShipping(data: IsFreeShippingDataType): boolean {
  const freeShippingPromo: boolean =
    (allEligiblePromos(data)?.filter(
      (promo) => promo.amount === "0.0" && promo.label.match(/free shipping/gi),
    )?.length ?? 0) > 0;
  return (
    ((data.shipTotal === "0.0" && freeShippingPromo) ||
      data.subscriptionInCart) === true
  );
}

export function shouldRenderFreeShippingBar(
  data: Pick<ChordData<any>, "lineItems">,
): boolean {
  const lineItemsWithGiftCards: ChordOrderLineItemShared[] =
    data.lineItems?.filter((lineItem) => lineItem.giftCards?.length > 0);
  const lineItemsWithSubs: ChordOrderLineItemShared[] = data.lineItems?.filter(
    (lineItem) => lineItem.subscriptionLineItems?.length > 0,
  );
  const lineItemsWithSubsAndALC = lineItemsWithSubs?.filter(
    (withSub) => withSub.quantity > withSub.subscriptionLineItems?.length,
  );
  if (
    lineItemsWithGiftCards?.length === 0 &&
    (data.lineItems?.length > lineItemsWithSubs?.length ||
      lineItemsWithSubsAndALC?.length > 0)
  ) {
    return true;
  }
  return false;
}

type ImageFromChordImageProps = {
  chordImage: ChordImage | undefined;
  defaultAltText?: string;
  defaultImageUrl: string;
};

function chordImageToImage({
  chordImage,
  defaultAltText = "Blue Bottle Image",
  defaultImageUrl,
}: ImageFromChordImageProps): Image {
  return {
    altText: chordImage?.alt || defaultAltText,
    src: chordImage?.smallUrl || defaultImageUrl,
  };
}

/**
 * Transforms an order being returned from Chord into the Order type
 * that Blue Bottle has implemented in the design system.
 */
export function getPriceText(price: Money): string {
  return Dinero({ amount: price.amount }).hasSubUnits()
    ? Dinero({
        amount: price.amount ?? 0,
        currency: price.currency,
      }).toFormat("$0,0.00")
    : Dinero({
        amount: price.amount ?? 0,
        currency: price.currency,
      }).toFormat("$0,0");
}

export function chordOrderAdapter(chordOrder: ChordOrderType): Order {
  return {
    ...chordOrder,

    state: chordOrder.state,
    billAddress: {
      address1: chordOrder.billAddress?.address1,
      address2: chordOrder.billAddress?.address2,
      city: chordOrder.billAddress?.city,
      countryIso: chordOrder.billAddress?.countryIso,
      name: chordOrder.billAddress?.name,
      phone: chordOrder.billAddress?.phone || "",
      stateText: chordOrder.billAddress?.stateText,
      zipCode: chordOrder.billAddress?.zipcode,
    } as OrderAddress,
    completedAt: chordOrder.completedAt
      ? new Date(chordOrder.completedAt)
      : undefined,
    createdAt: new Date(chordOrder.createdAt),
    currency: chordOrder.currency,
    /* totalApplicableStoreCredit is sent as a string via FINALIZE_CHECKOUT but as a number via LOAD_ORDER */
    displayTotalApplicableStoreCredit:
      chordOrder.totalApplicableStoreCredit !== "0.0" &&
      chordOrder.totalApplicableStoreCredit !== 0
        ? chordOrder.displayTotalApplicableStoreCredit
        : undefined,
    discountTotal: discountTotal(chordOrder),
    displayItemTotal: chordOrder.displayItemTotal,
    displayOrderTotalAfterStoreCredit:
      parseFloat(chordOrder.orderTotalAfterStoreCredit) > 0
        ? chordOrder.displayOrderTotalAfterStoreCredit
        : getPriceText({ amount: 0, currency: chordOrder.currency }),
    displayShipTotal: chordOrder.displayShipTotal,
    displayTaxTotal: chordOrder.displayTaxTotal,
    displayTotal: chordOrder.displayTotal,
    email: chordOrder.email,
    // gift properties ----- TODO
    lineItems: chordOrder.lineItems.map(
      (lineItem: ChordOrderLineItemShared) => {
        let desktopImage: Image;
        let mobileImage: Image;

        type DetailOrHistoryImage = ChordResponsiveImage;
        type ConfirmationImage = ChordImage;

        const variantImage: ConfirmationImage | DetailOrHistoryImage =
          lineItem.variant.images[0];

        if ((variantImage as DetailOrHistoryImage)?.desktop) {
          const image = variantImage as DetailOrHistoryImage;

          desktopImage = image.desktop;
          mobileImage = image.mobile || image.desktop;
        } else {
          const image = variantImage as ConfirmationImage;
          const sharedImage = chordImageToImage({
            chordImage: image,
            defaultImageUrl: variantFallbackImageSmall,
          });

          desktopImage = sharedImage;
          mobileImage = sharedImage;
        }

        return {
          id: lineItem.id,
          displayAmount: lineItem.displayAmount,
          giftCards: lineItem.giftCards,
          variant: {
            id: lineItem.variant.id,
            displayPrice: lineItem.variant.displayPrice,
            inStock: lineItem.variant.inStock,
            optionsText: lineItem.variant.optionsText,
            optionValues: lineItem.variant.optionValues.map(
              (optionValue) =>
                ({
                  optionTypePresentation: optionValue.optionTypePresentation,
                  presentation: optionValue.presentation,
                } as OptionValue),
            ),
            name: lineItem.variant.name,
            images: [
              {
                desktop: {
                  altText: desktopImage.altText,
                  src: desktopImage.src,
                },
                mobile: {
                  altText: mobileImage.altText,
                  src: mobileImage.src,
                },
              },
            ],
            sku: lineItem.variant.sku,
            slug: lineItem.variant.slug,
          },
          quantity: lineItem.quantity,
        } as OrderLineItem;
      },
    ),
    number: chordOrder.number,
    payments: chordOrder.payments.map(
      (payment) =>
        ({
          state: payment.state,
          /* orders created via OMS as "Free Order" have null payment source data */
          source: {
            id: payment.source?.id,
            month: +(payment.source?.month ?? 0),
            year: +(payment.source?.year ?? 0),
            ccType: payment.source?.ccType,
            lastDigits: +(payment.source?.lastDigits ?? 0),
            name: payment.source?.name,
          } as PaymentSource,
          displayAmount: payment.displayAmount,
        } as Payment),
    ),
    promoLabels: getPromoLabels(chordOrder),
    shipAddress: {
      address1: chordOrder.shipAddress?.address1,
      address2: chordOrder.shipAddress?.address2,
      city: chordOrder.shipAddress?.city,
      countryIso: chordOrder.shipAddress?.countryIso,
      name: chordOrder.shipAddress?.name,
      phone: chordOrder.shipAddress?.phone || "",
      stateText: chordOrder.shipAddress?.stateText,
      zipCode: chordOrder.shipAddress?.zipcode,
    } as OrderAddress,
    shipments: chordOrder.shipments.map(
      (shipment) =>
        ({
          trackingUrl: shipment.trackingUrl,
          externalTrackingUrl: shipment.externalTrackingUrl,
          number: shipment.number,
          selectedShippingRate: shipment.selectedShippingRate,
          state: shipment.state,
          manifest: shipment.manifest.map(
            (manifestItem) =>
              ({
                variantId: manifestItem.variantId,
                quantity: manifestItem.quantity,
              } as Manifest),
          ),
        } as unknown as Shipment),
    ),
  };
}

type ChordSubDateLineItems = {
  intervalUnits: string;
  intervalLength: number;
  actionableDate?: string;
};

type ShipmentDateObjects = {
  currentShipmentDate: Date;
  nextShipmentDate: Date;
};

/**
 * Uses a Chord Subscription object's line items to generate Date objects
 * for the current shipment date and the next shipment date.
 * @param {ChordSubDateLineItems} dateLineItems - The actionable date,
 *  interval units, and interval length of a subscription.
 * @returns {ShipmentDateObjects} - Date objects representing the current
 *  shipment date and the next shipment date.
 */
export function createShipmentDateObjects(
  dateLineItems: ChordSubDateLineItems,
): ShipmentDateObjects {
  const { actionableDate, intervalUnits, intervalLength } = dateLineItems;
  let currentShipmentDate = new Date();

  if (actionableDate) {
    currentShipmentDate = actionableDate.includes("T")
      ? new Date(actionableDate)
      : new Date(`${actionableDate}T00:00`);
  }

  const nextShipmentDate =
    intervalUnits === "week"
      ? addDays(currentShipmentDate, intervalLength * 7)
      : addMonths(currentShipmentDate, intervalLength);

  return {
    currentShipmentDate,
    nextShipmentDate,
  };
}
/**
 * Sets Iterable campaign and template ID cookies that are used to attribute revenue
 * to marketing campaigns.
 *
 * @see https://support.iterable.com/hc/en-us/articles/205480285-Tracking-Purchases-and-Revenue-#use-browser-cookies-set-by-iterable
 * @export
 */
export function setIterableRevenueAttributionCookies() {
  // Retrieve the Iterable campaign ID from the cookie that Iterable sets
  const iterableEmailCampaignId = cookie.get("iterableEmailCampaignId");
  const iterableTemplateId = cookie.get("iterableTemplateId");

  // Retrieve our exclusion list for Iterable campaigns that we do not
  // want to attribute revenue to, such as the Magic Link campaign.
  // This is read from an environment variable, that is a space
  // delimited list of Iterable campaign IDs we want to exclude.
  const revenueAttributionExclusions: Array<string> =
    process.env.NEXT_PUBLIC_ITERABLE_REVENUE_ATTRIBUTION_CAMPAIGN_EXCLUSIONS?.split(
      ",",
    );

  // If the campaign ID is NOT in our exclusions list, we set new cookies
  // with the Iterable campaign and template Ids
  if (
    iterableEmailCampaignId &&
    !revenueAttributionExclusions?.includes(iterableEmailCampaignId)
  ) {
    cookie.set(
      "iterableEmailCampaignIdRevenueAttributable",
      iterableEmailCampaignId,
    );
    cookie.set(
      "iterableEmailTemplateIdRevenueAttributable",
      iterableTemplateId,
    );
  }
}

/**
 * Assign `key`/`val` pair to `obj` if `val` is not null
 *
 * @param obj Object to which `key`/`val` pair should be assigned
 * @param key Desired key
 * @param val Desired value
 */
export function assignIfExists(obj: object, key: string, val: any) {
  if (val) Object.assign(obj, { [key]: val });
}

/**
 * Checks a product object to see if it has multiple variants. If so, checks that all
 * optionType and optionValue fields are present.
 * @param {AlgoliaRecommendProduct | ConversionSchema} product - The product object from
 * the Algolia Recommend Client or Sanity
 * @param {string} lang - The localized page language
 * @returns {boolean} - true if product only has 1 variant, or if product has multiple
 * variants and all required optionType and optionValue fields. False if otherwise.
 */
export function hasOptionValues(
  product: AlgoliaRecommendProduct | ConversionSchema,
  lang: string,
): boolean {
  // multiple variants require option type and value
  if (product.variants.length > 1) {
    // option type check
    if (!product.optionTypes || product.optionTypes.length === 0) {
      console.log(
        `${product.name[lang]} has multiple variants but no optionType, skipping...`,
      );
      return false;
    }

    // option value check
    // eslint-disable-next-line no-lonely-if
    if (
      !product.variants.every((v) => {
        if (!v.optionValues || v.optionValues.length === 0) {
          console.log(
            `${product.name[lang]} has a variant ${v.label[lang]} with no optionValue`,
          );
          return false;
        } else {
          return true;
        }
      })
    )
      return false;
  }

  return true;
}
