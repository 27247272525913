import { AuthErrorCodes } from "../../../services/auth/error";
import { assignCurrentCart } from "../cart";
import {
  AUTH_IDENTIFY_ERROR,
  AUTH_IDENTIFY_REQUEST,
  AUTH_IDENTIFY_SUCCESS,
} from "../types";
import auth from "../../../services/auth";

export const identifyRequest = (email) => ({
    type: AUTH_IDENTIFY_REQUEST,
    payload: {
      email,
    },
  });

export const identifySuccess = (token) => ({
    type: AUTH_IDENTIFY_SUCCESS,
    payload: {
      token,
    },
  });

export const identifyError = (error, meta = {}) => ({
    type: AUTH_IDENTIFY_ERROR,
    error: true,
    meta,
    payload: {
      error,
    },
  });

export const handleCallback = () => async (dispatch) => {
  try {
    dispatch(identifyRequest());
    const token = await auth.handleCallback();
    dispatch(identifySuccess(token));
  } catch (error) {
    dispatch(identifyError(error));
    throw error;
  }

  // If the user has a current cart, associate it with the logged in user
  await dispatch(assignCurrentCart());
};

export const getToken = () => async (dispatch) => {
  try {
    dispatch(identifyRequest());
    const token = await auth.getToken();
    dispatch(identifySuccess(token));
  } catch (error) {
    if (error.code !== AuthErrorCodes.Unauthorized) {
      dispatch(identifyError(error));
    }
    throw error;
  }
};
