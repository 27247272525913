import {
  ClaimGiftCardModelContentProps,
  LoginGiftCardModalContentProps,
} from "@bluebottlecoffee/design-system";
import { GiftCardRedemption } from "../sanity-schema";
import { toImageProps } from "./image";

export function toGiftCardRedemptionProps(
  data: GiftCardRedemption,
  lang: string,
  email: string,
): {
  claimGiftCardModelContentProps: ClaimGiftCardModelContentProps;
  loginGiftCardModalContentProps: LoginGiftCardModalContentProps;
} {
  return {
    claimGiftCardModelContentProps: {
      icon:
        data.claimGiftCardContent?.icon &&
        toImageProps(data.claimGiftCardContent?.icon, lang),
      copy: {
        content: data.claimGiftCardContent.copy.content[lang],
        submitButtonText: data.claimGiftCardContent.copy.submitButtonText[lang],
        title: data.claimGiftCardContent.copy.title[lang],
      },
    },
    loginGiftCardModalContentProps: {
      copy: {
        confirmEmailConfirmation:
          data.loginGiftCardContent.copy.confirmEmailConfirmation[lang],
        confirmModalInstructions:
          data.loginGiftCardContent.copy.confirmModalInstructions[lang],
        title: data.loginGiftCardContent.copy.title[lang],
      },
      email,
      icon:
        data.loginGiftCardContent?.icon &&
        toImageProps(data.loginGiftCardContent.icon, lang),
    },
  };
}
