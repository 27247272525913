import {
  AnnouncementBar,
  BrewGuide,
  BrewGuidesPage,
  Cafe,
  CafesPage,
  Cart,
  CoffeeFulfillmentCutOff,
  CoffeeFulfillmentHolidays,
  Config,
  CurrentSubscriptionsPage,
  EmailOptIn,
  ErrorPage,
  Footer,
  GiftCardRedemption,
  HelpPage,
  Homepage,
  InstantEspresso,
  Nav,
  OurCoffee,
  OurStory,
  OurValues,
  Page,
  PrivacyPage,
  Product,
  ProductRec,
  ShippingCopy,
  ShopCollection,
  ShopPage,
  SiteWidePromotion,
  SubscriptionCancellation,
  SubscriptionPage,
  Sustainability,
  TermsOfUsePage,
  TranslationGroup,
} from "../sanity-schema";

// Please maintain alphabetical order
type DocumentType =
  | AnnouncementBar["_type"]
  | BrewGuide["_type"]
  | BrewGuidesPage["_type"]
  | Cafe["_type"]
  | CafesPage["_type"]
  | Cart["_type"]
  | CoffeeFulfillmentCutOff["_type"]
  | CoffeeFulfillmentHolidays["_type"]
  | Config["_type"]
  | CurrentSubscriptionsPage["_type"]
  | EmailOptIn["_type"]
  | ErrorPage["_type"]
  | Footer["_type"]
  | GiftCardRedemption["_type"]
  | HelpPage["_type"]
  | Homepage["_type"]
  | InstantEspresso["_type"]
  | Nav["_type"]
  | OurCoffee["_type"]
  | OurStory["_type"]
  | OurValues["_type"]
  | Page["_type"]
  | PrivacyPage["_type"]
  | ProductRec["_type"]
  | Product["_type"]
  | ShippingCopy["_type"]
  | ShopCollection["_type"]
  | ShopPage["_type"]
  | SiteWidePromotion["_type"]
  | SubscriptionCancellation["_type"]
  | SubscriptionPage["_type"]
  | Sustainability["_type"]
  | TermsOfUsePage["_type"]
  | TranslationGroup["_type"];

export const getLocalizedString = <T>(key: keyof T, lang: string) =>
  `${key.toString()}["${lang}"]`;

export const getSlug = <T>(key: keyof T) => `${key.toString()}.current`;

/**
 * Validates that a key exists on type `T`
 *
 * Intended to be used in constructing Sanity queries, as means to achieve a level
 * of "type safety" around changes to the Sanity schema and/or custom Sanity query
 * return types.
 *
 * This affords us the ability to update our types using the `yarn type-gen`
 * command and immediately know where we need to update our queries by running
 * `yarn build`. Without the type safety afforded by this function, you would
 * most likely be left with a not so helpful error at build time.
 *
 * @template T Type to check
 *
 * @param { string } key Key to check for on type `T`
 *
 * @returns { string } The provided key
 */
export const keyof = <T>(key: keyof T) => key;

/** Sanity filter to only get published data */
const excludeDrafts = '!(_id in path("drafts.**"))';

/** Sanity filter to only get one matching the <slugField>.current */
const matchSlug = <T>(slugField: keyof T, slug: string) =>
  `${getSlug<T>(slugField)} == "${slug}"`;

/** Sanity filter to target a document type */
const whereType = (type: DocumentType) => `_type=="${type}"`;

/** All the shared Sanity filter utilities */
export const filters = {
  excludeDrafts,
  matchSlug,
  whereType,
};
