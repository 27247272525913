/* eslint-disable import/no-unresolved */
import {
  ADD_TO_CART_ERROR,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
} from "~/redux/actions/types";
import { getOrLoadCart } from "./get-or-load-cart";

export const addToCartRequest = (sku) => ({
  type: ADD_TO_CART_REQUEST,
  sku,
});

export const addToCartSuccess = (
  cart,
  variant,
  quantity,
  dynamicPromotionsAttributes,
) => ({
  type: ADD_TO_CART_SUCCESS,
  data: cart,
  variant,
  quantity,
  dynamicPromotionsAttributes,
});

export const addToCartError = (error, meta = {}) => ({
  type: ADD_TO_CART_ERROR,
  error: true,
  meta,
  payload: error,
});

export const addToCart =
  (sku, quantity) =>
  async (dispatch, getState, { api }) => {
    const {
      siteWidePromotion: { code },
    } = getState();

    const dynamicPromotions = code ? [{ code }] : null;

    try {
      dispatch(addToCartRequest(sku));

      const { data: cart } = await getOrLoadCart(dispatch, getState);

      await api.addToCart(cart, sku, quantity, dynamicPromotions);

      const updatedCart = await api.fetchCart(cart.number);

      const addLineItem = updatedCart.lineItems.find(
        (li) => li.variant.sku === sku,
      );
      const { variant } = addLineItem;
      dispatch(
        addToCartSuccess(updatedCart, variant, quantity, dynamicPromotions),
      );
    } catch (error) {
      dispatch(addToCartError(error, { sku, quantity }));
      throw error;
    }
  };
