import {
  SlideOverDialogContextProvider,
  ScreenSizeContextProvider,
} from "@bluebottlecoffee/design-system";
import "@bluebottlecoffee/design-system/dist/index.css";
import "../styles/globals.css";
import Honeybadger from "@honeybadger-io/js";
import Head from "next/head";
import {
  getIsClientSide,
  setIterableRevenueAttributionCookies,
} from "../lib/utils";
import { wrapper } from "../lib/chord/packages/web/src/redux/store";
import { assignEventProps } from "../lib/segment/middleware";
import { ConditionalConsentManager } from "../components/ConsentManagerWrapper";
import { ConditionalEmailOptInModal } from "../components/ConditionalEmailOptInModal";
import { ConditionalGiftCardRedemptionModal } from "../components/ConditionalGiftCardRedemption";
import beforeNotifyHandler from "../lib/utils/honeybadger-before-notify";

Honeybadger.configure({
  apiKey: process.env.NEXT_PUBLIC_HONEYBADGER_API_KEY,
  revision: process.env.NEXT_PUBLIC_HONEYBADGER_REVISION,
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT || "development",
  projectRoot: "webpack://_N_E/",
  // uncomment to report errors in development
  // reportData: true,
});
Honeybadger.beforeNotify(beforeNotifyHandler);

function MyApp({ Component, pageProps }) {
  setIterableRevenueAttributionCookies();

  // Add Segment Middleware
  if (getIsClientSide()) {
    window.analytics.addSourceMiddleware(assignEventProps);
  }

  return (
    <>
      <Head>
        {process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION_ID && (
          <meta
            name="google-site-verification"
            content={process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION_ID}
          />
        )}
      </Head>
      <div id="modal-root" />
      <SlideOverDialogContextProvider>
        <ScreenSizeContextProvider>
          <ConditionalGiftCardRedemptionModal pageProps={pageProps} />
          <ConditionalEmailOptInModal pageProps={pageProps} />
          <ConditionalConsentManager pageProps={pageProps}>
            <Component {...pageProps} />
          </ConditionalConsentManager>
        </ScreenSizeContextProvider>
      </SlideOverDialogContextProvider>
    </>
  );
}

export default wrapper.withRedux(MyApp);
