import { LinkProps } from "@bluebottlecoffee/design-system";
import { Link as Schema } from "../sanity-schema";

type InternalUrlProps = {
  link: string;
  region: string;
  lang: string;
};

export function toInternalUrl({
  link,
  region,
  lang,
}: InternalUrlProps): string {
  const noLeadingSlash = link.charAt(0) !== "/" ? link : link.substring(1);
  return `/${region}/${lang}/${noLeadingSlash}`;
}

export function toLinkProps(
  data: Schema,
  region: string,
  lang: string,
): LinkProps {
  const url: string = data.external
    ? data.external
    : toInternalUrl({ link: data.internal!, region, lang });
  return {
    text: data.text[lang],
    url,
    isAnchorLink: data.isAnchorLink ?? false,
  };
}
