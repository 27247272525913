import {
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_IDENTIFY_SUCCESS,
} from "../types";
import auth from "../../../services/auth";
import { assignCurrentCart } from "../cart";

export const loginRequest = (email) => ({
  type: AUTH_LOGIN_REQUEST,
  payload: {
    email,
  },
});

export const loginSuccess = (payload) => ({
  type: AUTH_LOGIN_SUCCESS,
  payload,
});

export const loginError = (error, meta = {}) => ({
  type: AUTH_LOGIN_ERROR,
  error: true,
  meta,
  payload: {
    error,
  },
});

const identifySuccess = (token) => ({
  type: AUTH_IDENTIFY_SUCCESS,
  payload: {
    token,
  },
});

export const login =
  ({ email, showUI = false, redirectURI = window.location.href }) =>
  async (dispatch) => {
    try {
      dispatch(loginRequest(email));
      await auth.login({ email, showUI, redirectURI });
      const token = await auth.getToken();
      dispatch(identifySuccess(token));
      const metadata = await auth.getUser();
      dispatch(loginSuccess({ email: metadata.email, token }));
    } catch (error) {
      dispatch(loginError(error, { email, showUI, redirectURI }));
      throw error;
    }

    // If the user has a current cart, associate it with the logged in user
    await dispatch(assignCurrentCart());
  };
